.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(230, 230, 230, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.overlay-content {
  font-size: 14px;
  position: relative;
  max-width: 550px;
  width: 100%;
  padding: 35px 25px;
  // min-height: 200px;
  background-color: white;
  border-radius: 15px;
  // border: 1px solid rgb(230, 230, 230);
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);
  overflow-y: scroll;
}

.overlay-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
  margin: 9px;
  font-size: 20px;
  z-index: 20000;
  color: rgb(50, 50, 50);
}

.overlay-close:hover {
  cursor: pointer;
  // color: crimson;
}