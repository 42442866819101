@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');
pre {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  border-radius: 8px !important;
}

pre > code {
  margin: 0 !important;
  padding: 20px !important;
  min-height: 200px;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace;
  box-shadow: none;
  --weight: 400;
}

.white-hljs > * {
  color: rgb(171, 178, 191) !important;
}

.hljs {
  border: none;
  box-shadow: none;
  overflow: visible;
  color: #abb2bf;
  background: #282c34;
  font-weight: var(--weight);
  white-space: pre-wrap;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
  font-weight: var(--weight);
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
  color: #c678dd;
  font-weight: var(--weight);
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
  color: #e06c75;
  font-weight: var(--weight);
}

.hljs-literal {
  color: #56b6c2;
  font-weight: var(--weight);
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta .hljs-string {
  color: #98c379;
  font-weight: var(--weight);
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
  color: #d19a66;
  font-weight: var(--weight);
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
  color: #61aeee;
  font-weight: var(--weight);
}

.hljs-built_in,
.hljs-title.class_,
.hljs-class .hljs-title {
  color: #e6c07b;
  font-weight: var(--weight);
}

.hljs-emphasis {
  font-style: italic;
  font-weight: var(--weight);
}

.hljs-strong {
  // font-weight: bold;
  font-weight: var(--weight);
}

.hljs-link {
  text-decoration: underline;
  font-weight: var(--weight);
}