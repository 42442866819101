@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');
.bannerImage {
  position: absolute;
  height: calc(500px + 0.6vw);
  width: calc(500px + 0.6vw);
  max-width: calc(100% - 30px);
  max-height: 500px;
  top: 20px;
  right: 20px;
  overflow: hidden;
}