.block1 { 
  grid-area: blk1; 
  position: relative;
}
.block2 { 
  grid-area: blk2; 
  // max-height: 150px;
  padding: 0;
}
.block3 { 
  grid-area: blk3; 
  // max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  // color: rgb(150, 150, 150);
  color: rgb(120, 120, 120);
  transition: all 250ms ease-in-out 50ms;
  border: 5px solid transparent;
  overflow: hidden;
}

.block4 {
  grid-area: blk4; 
  // max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: rgb(120, 120, 120);
  text-align: center;
  transition: all 250ms ease-in-out 50ms;
  border: 5px solid transparent;
  position: relative;
}

.sellagen-deck-grid {
  max-width: 1150px;
  color: rgb(70, 70, 70);
  width: 100%;
  margin: auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 
    'blk1 blk1 blk2 blk2'
    'blk1 blk1 blk3 blk4';
  gap: 20px;
  padding: 20px;
  border: 4px solid rgb(246, 248, 250);
  border-radius: 20px;
}

.sellagen-grid-unit {
  // border: 1.5px solid rgb(200, 200, 200);
  background-color: rgb(246, 248, 250);
  border-radius: 10px;
  min-height: 150px;
}

.bullet-Container {
  margin: 15px 0 18px 0;
  font-size: 14px;
  display: inline-block;
}

.bullet-points {
  color: rgb(90, 90, 90);
  padding: 4px 0;
  font-weight: 500;
}

.icon-margin {
  width: 25px;
}

.deck-button {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  padding: 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  background-color: rgb(29, 191, 113);
  color: white;
  text-align: center;
}

.deck-button:hover {
  background-color: rgb(26, 167, 99);
  cursor: pointer;
}

.field-container {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
}

.field-tags {
  display: inline-block;
  // background-color: rgb(49, 212, 133);
  background-color: rgb(235, 235, 235);
  font-size: 10px;
  padding: 10px;
  border-radius: 7px;
  color: rgb(70, 70, 70);
  margin-right: 8px;
  margin-bottom: 8px;
}

.block3:hover {
  cursor: pointer;
  border: 5px solid rgb(120, 120, 120);
}

// .block4:hover {
//   cursor: pointer;
//   border: 5px solid rgb(120, 120, 120);
//   background-color: transparent;
// }

.map-viewer {
  width: 90%;
}

.marker-container {
  position: absolute;
  padding: 5px;
  top: 0;
  left: 0;
  height: 30px;
  font-size: 20px;
}

.location-tooltip {
  font-size: 12px;
  padding: 10px;
  background-color: rgb(246, 248, 250);
  color: rgb(90, 90, 90);
  position: absolute;
  top: 90%;
  left: 90%;
  border: 1px solid rgb(29, 191, 113);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marker-container .location-tooltip {
  visibility: hidden;
}

.marker-container:hover {
  cursor: pointer;
}

.marker-container:hover .location-tooltip {
  visibility: visible;
}

@media only screen and (max-width: 675px) {
  .sellagen-deck-grid {
    width: calc(100% - 10px);
    grid-template-areas: 
    'blk1 blk1 blk1 blk1'
    'blk2 blk2 blk2 blk2'
    'blk3 blk3 blk4 blk4';
    gap: 15px;
    padding: 15px;
    border: 4px solid rgb(246, 248, 250);
    border-radius: 15px;
  }

  .deck-button {
    position: relative;
    bottom: unset;
    width: 100%;
  }
}

// #my-world {
//   background: rgb(246, 248, 250);
//   width: 600px;
//   height: 300px;
//   margin: 30px auto;
//   padding: 30px;
//   justify-content: center;
//   align-items: center;
// }

.img-slider2 {
  position: relative;
  width: 100%;
  height: 100%;
  border: none !important;
}
.img-slider2 .slider-container2 {
  position: absolute;
  width: 500%;
  top: 0;
  left: -100%;
  height: 100%;
  overflow: hidden;
  animation: slider 15s infinite;
  &:hover {
    animation-play-state: paused;
  }
}
.img-slider2 .slider-container2 .slide2 {
  position: relative;
  width: 20%;
  height: 100%;
  float: left;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-slider2 .slider-container2 .slide2 .slide-image2 {
  padding: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}

.img-slider2 .slider-container2 .slide2 .slide-content2 {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
}

$item-count: 5;

@keyframes slider {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25%) - 22%}, #{$i * 25%} {
      left: calc(-100% * $i);
    }
  }
}

.sellagen-deck-title {
  width: 100%;
  padding: 0px;
  max-width: 1150px;
  font-size: 30px;
  font-weight: 700;
  color: rgb(90, 90, 90);
  margin: 80px auto 0px auto;
}