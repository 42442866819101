.sv3-ellipsis-container {
  display: inline-flex;
  max-width: 100%;
}

.sv3-ellipsis-main {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}