.cardContent {
  --card-font-color: rgb(50, 50, 50);
  padding: 20px;
  font-size: 16px;
  position: relative;
  border-radius: 10px;
  color: var(--card-font-color);
  background-color: rgb(246, 248, 250);
  border: 2px solid rgb(240, 242, 244);
}

.sellagen-tag {
  padding: 2px 10px;
  font-size: 11px;
  border-radius: 5px;
  // font-weight: 700;
}

.green {
  background-color: rgb(215, 247, 194);
  color: rgb(1, 105, 7);
}

.yellow {
  background-color: rgb(255, 230, 132);
  color: rgb(140, 107, 0);
}

.red {
  background-color: rgb(249, 160, 155);
  color: rgb(161, 6, 5);
}

.seller {
  font-size: 11px;
  // color: rgb(50, 50, 50);
  overflow: visible;
}

.category-card {
  height: 50px;
  // width: 100px;
  min-width: 50px;
  position: absolute;
  // background-color: red;
  padding: 0 10px;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // color: rgb(80, 80, 80);
  // color: teal;
  font-size: 26px;
}

.item-edit {
  font-size: 20px;
}

.item-edit:hover {
  color: rgb(29, 191, 113);
}

.title {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100px;
  // background-color: green;
  // font-size: 1.5vw;
  font-size: calc(12px + 0.6vw);
  // font-size: 22px;
  // padding: 20px 0 0px 0;
  color: var(--card-font-color);
  line-height: 1.3;
}


.divider {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  margin-bottom: 16px;
}

.sellagen-hyperlink {
  font-weight: 700;
}

.sellagen-hyperlink:hover {
  text-decoration: underline;
  cursor: pointer;
}