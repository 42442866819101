.sellagen-prompt-title {
  font-size: 24px;
  margin: 0 0 20px 0;
}

.sellagen-prompt-animation {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  stroke: var(--primary-color3);
}

.sellagen-para {
  font-size: 14px;
  line-height: 1.5;
}

.sellagen-propmt-link {
  // color: var(--primary-color3);
  text-decoration: underline;
  background-color: transparent;
}

.sellagen-propmt-link:hover {
  text-decoration: underline;
}

.sellagen-prompt-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  border-radius: 10px;
  margin: 25px 0 0 0;
}

.outlined-style {
  background-color: transparent;
  border: 2px solid var(--primary-color3);
  color: var(--primary-color3);
}

.outlined-style:hover {
  background-color: var(--primary-color3);
  border: 2px solid var(--primary-color3);
  color: white;
}

.outlined-style:disabled,
.outlined-style[disabled] {
  cursor: default;
  background: transparent;
  border-color: rgb(200, 200, 200);
  color: rgb(200, 200, 200);
}

.loading-style {
  background-color: var(--primary-color3);
  border: 2px solid var(--primary-color3);
  color: white;
}

.loading-style:hover {
  background-color: var(--primary-color3);
  border: 2px solid var(--primary-color3);
  color: white;
  cursor: not-allowed;
}

.close-mask {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20001;
  height: 50px;
  width: 50px;
  background-color: white;
}