.sv2-flowbase {
  --flow-bg: 26, 29, 34;
  --flow-color: 215, 215, 215;
  background-color: rgb(var(--flow-bg));
  color: rgb(var(--flow-color));
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  // margin-bottom: 20px;
}

.sv2-flowbase-connector {
  --flow-connector-color: 180, 180, 180;
  --flow-connector-size: 10px;
  background-color: transparent;
  position: relative;
}


.sv2-flowbase-connector > .start {
  position: absolute;
  width: var(--flow-connector-size);
  height: var(--flow-connector-size);
  border-radius: 100%;
  background-color: rgb(var(--flow-connector-color));
  z-index: 90;
  left: 50%;
  top: -1px;
  transform: translate(-50%, -50%);
}

.sv2-flowbase-connector > .mid {
  position: absolute;
  width: calc(var(--flow-connector-size) - 2px);
  height: calc(var(--flow-connector-size) - 2px);
  border-radius: 100%;
  background-color: rgb(var(--flow-connector-color));
  z-index: 90;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sv2-flowbase-connector .mid-label {
  position: absolute;
  left: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
}

.sv2-flowbase-connector > .end {
  position: absolute;
  width: var(--flow-connector-size);
  height: var(--flow-connector-size);
  border-radius: 100%;
  background-color: rgb(var(--flow-connector-color));
  z-index: 90;
  left: 50%;
  bottom: -1px;
  transform: translate(-50%, 50%);
}

.sv2-flowbase-connector > .line {
  position: absolute;
  width: 0;
  height: 100%;
  z-index: 89;
  // border: 1px solid rgb(var(--flow-connector-color));
  // border: 1px dashed rgba(255, 255, 255, 0.2);
  border: 1px dashed rgba(29, 191, 113, 0.6);
  left: 50%;
  transform: translateX(-50%);
}

.sv2-flowbase:last-child {
  margin-bottom: 0;
}

.sv2-flow-modal {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 91;  // just above flow-connectors (which are 90)
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sv2-flowtitle {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
}

.sv2-flowtitle > .title-mod {
  filter: brightness(80%);
  font-size: 10.5px;
  font-weight: 500;
}

.sv2-flowtitle > .title-icon {
  font-size: 14px;
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  color: rgb(var(--flow-color));
}

.sv2-flow-stacked-body {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
}

.sv2-flow-stacked-body > .title-icon {
  overflow: hidden;
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  position: relative;
}

.sv2-flow-stacked-body > .title-mod {
  flex-grow: 1;
}

.title-icon > .square {
  width: 100%;
  height: 100%;
  border: 2px solid rgb(var(--flow-color));
  position: absolute;
  // bottom: 47.5%;
  bottom: calc(100% - 15.9px);    //todo: fix this (no static value)
  left: 47.5%;
  border-radius: 3px;
  filter: brightness(50%);
}

.sv2-flow-tags {
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
  color: rgb(var(--flow-color));
}

.sv2-flow-dropdown {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  position: relative;
  --flow-dropdown-color: inherit;
}

.sv2-flow-ip-manager { // controls (responsive) width of input and dropdown
  width: 200px;
}

.sv2-flow-ip-width { // controls (responsive) width of input and dropdown
  width: 216.5px;
}

.sv2-flow-dropdown-block {
  display: flex;
}

.sv2-flow-input {
  position: relative;
  font-size: 12px;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  color: rgb(var(--flow-color));
  position: relative;
  padding: 2px 4px;
  text-align: left;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
  outline: none;
}

.sv2-flow-textarea {
  width: 100%;
  min-height: 100px;
  max-height: 250px;
  background-color: transparent;
  padding: 5px;
  font-size: 11px;
  margin: 0;
  line-height: 1.25;
  border: 2px solid rgba(255, 255, 255, 0.12) !important;
  color: rgb(var(--flow-color));
  caret-color: rgb(var(--flow-color));
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
}

.sv2-flow-textarea::selection {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.sv2-flow-textarea::placeholder {
  margin: 0;
  line-height: 1.25 !important;
  font-size: 11px;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
  color: rgba(var(--flow-color), 0.55);
}

.sv2-flow-input::placeholder {
  color: rgba(var(--flow-color), 0.7);
}

.sv2-flow-dropdown-options {
  user-select: none;
  padding: 3px 4px;
  text-align: right;
  // width: 200px;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.sv2-flow-dropdown-expand {
  position: absolute;
  z-index: 100;
  top: calc(100% + 5px);
  right: -2px;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background-color: rgb(var(--flow-bg));
}

.sv2-flow-dropdown-expand .sv2-flow-dropdown-options {
  padding: 3.25px 4px;
}

.sv2-flow-dropdown-expand .sv2-flow-dropdown-block:hover {
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--flow-dropdown-color);
}

.sv2-flow-attribute {
  background-color: transparent;
  border-radius: 4px;
  padding: 3px 4px;
  // border: 2px solid rgba(255, 255, 255, 0.1);
  width: 120px;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: inline-flex;
}

.sv2-flow-btn {
  font-size: 10px;
  width: fit-content !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.sv2-flow-btn:hover {
  filter: brightness(125%);
  cursor: pointer;
}

.sv2-flow-btn-disabled {
  filter: brightness(80%) !important;
  color: rgb(var(--flow-color)) !important;
  cursor: not-allowed !important;
}

.sv2-flow-funcprop-row {
  // height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10.5px;
  padding-left: 10px;
  margin-bottom: 3px;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important; 
}

.sv2-flow-row-attr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  // border: 0.5px solid white;
  padding: 0 3px;
  line-height: 1;
}

.sv2-flow-funcprop-row .name {
  width: 95px;
  padding: 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sv2-flow-funcprop-row .dtype {
  width: 90px;
  padding-right: 0;
  justify-content: flex-end;
}

.sv2-flow-funcprop-row .details {
  font-size: 10px;
  flex-grow: 1;
}

.sv2-flow-funcprop-row .delete {
  width: 20px;
  font-size: 14px;
  justify-content: center;
}

.sv2-flow-funcprop-row .delete-btn {
  display: none;
}

.sv2-flow-funcprop-row:hover .delete-btn {
  display: block;
}

.sv2-flow-funcprop-row .delete-btn:hover {
  cursor: pointer;
  color: crimson;
}

.sv2-flow-dtype {
  background-color: rgba(255, 255, 255, 0.12);
  font-size: 10px;
  padding: 3px 5px;
  max-width: 75px;
  border-radius: 3.5px;
  display: inline-block;
  line-height: 1;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.details .sv2-flow-dtype {
  padding: 3px 3px;
  margin-right: 3px;
  user-select: none;
}

.sv2-flow-dtype-btn:hover {
  cursor: pointer;
  // color: rgb(29, 191, 113);
  color: rgb(255, 198, 93);
}

.sv2-flow-enum-add {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 3px solid transparent;
  right: 3.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.12);
  padding: 0 2px;
  transition: color 0.15s ease-in;
}

.sv2-flow-enum-add:hover {
  filter: brightness(120%);
  cursor: pointer;
}

.sv2-flow-enum-collections {
  display: inline-block;
  // flex-grow: 1;
  width: calc(100% - 30px);
  max-height: 100px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 3px;
  padding-bottom: 0;
  overflow-y: scroll;
}

.sv2-flow-enum-tag {
  font-size: 11px;
  padding: 2px 4px;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  line-height: 1.2;
  cursor: pointer;
  margin-right: 3px;
  margin-bottom: 3px;
}

.sv2-flow-enum-tag:last-child {
  margin-right: 0;
}

.sv2-flow-enum-tag:hover i {
  color: crimson;
}

.sv2-flow-propmt-textarea {
  padding-right: 25px;
  min-height: 50px;
  max-height: 100px;
}

.sv2-flow-prompt-add {
  height: calc(100% - 7px);
}

// function-fillup.jsx starts

// function-fillup.jsx starts

@media only screen and (max-width: 1100px) {
  .sv2-flow-ip-manager {
    width: calc(200px - 25px);
  }
  .sv2-flow-ip-width {
    width: calc(216.5px - 25px);
  }
}

@media only screen and (max-width: 1000px) {
  .sv2-flow-ip-manager {
    width: calc(200px - 50px);
  }
  .sv2-flow-ip-width {
    width: calc(216.5px - 50px);
  }
}

@media only screen and (max-width: 900px) {
  .sv2-flow-ip-manager {
    width: 125px;
  }
  .sv2-flow-ip-width {
    width: calc(216.5px - 75px);
  }
}

@media only screen and (max-width: 700px) {
  .sv2-flow-ip-manager {
    width: 200px;
  }
  .sv2-flow-ip-width {
    width: 216.5px;
  }
}