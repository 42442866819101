.sndbx-root {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  // --global-font-family: "Open Sans", sans-serif;
  // --global-font-family: "Source Sans 3", serif;
}

.wrapper-style .sndbx-root {
  position: relative;
  max-width: 1150px;
  height: 600px;
  margin: auto;
  // margin-top: 230px;
  // margin-bottom: -60px;
}

.intro-profile {
  display: flex;
  line-height: 2;
  z-index: 100;
  align-items: center;
  justify-content: right;
  position: absolute;
  width: 100%;
  padding: 20px 10px;
  top: 0;
  right: 0;
}

.wrapper-style .intro-profile {
  display: none;
}

.intro-profile-temp-menu {
  position: absolute;
  top: 50px;
  right: 20px;
  border-radius: 5px;
  padding: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto Mono;
}

.intro-temp-menu-option {
  width: 140px;
  padding: 2px 10px;
  border-radius: 4px;
  background-color: var(--theme-main2);
  margin-bottom: 3px;
}

.intro-temp-menu-option:last-child {
  margin-bottom: 0;
}

.intro-temp-menu-option span {
  float: right;
}

.intro-temp-menu-option:hover {
  cursor: pointer;
  filter: brightness(110%);
}

.wrapper-style .sndbx-container {
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.sndbx-nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // height: 65px;
  background: linear-gradient(to bottom, rgba(22, 23, 26, 1.0), rgba(32, 33, 36, 0.0));
  z-index: 1000;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  // padding: 0 30px;
  // background-color: red;
}

.sndbx-logo {
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
}

.wrapper-style .sndbx-nav-bar {
  display: none;
}

.sndbx-full {
  width: 100vw;
  height: 100vh;
}

.sndbx-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  background-color: var(--theme-main);
  color: white;
  padding: 0px;
}

.sndbx-right-panel-toggle, .sndbx-right-panel-toggle-reverse {
  padding: 20px 3px 20px 6px;
  font-size: 18px;
  z-index: 1;
  position: absolute;
  background-color: var(--theme-main3);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px 0 0 5px;
}

.sndbx-right-panel-toggle-reverse {
  padding: 20px 5px 20px 6px;
  z-index: 1;
}

.sndbx-right-panel-toggle:hover, .sndbx-right-panel-toggle-reverse:hover {
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .wrapper-style .sndbx-root {
    padding: 5px;
  }
}

.sndbx-root .sv2-spdrn-right-panel {
  max-width: 400px;
  width: 100%;
  z-index: 2;
}

.sndbx-panel-close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-right: 20px;
  // margin-left: -10px;
  display: none;
}

.sndbx-panel-setting {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 30px;
  font-weight: 500;
  filter: brightness(80%);
}

.sndbx-panel-setting:hover {
  cursor: pointer;
  text-decoration: underline;
  filter: brightness(100%);
}

.sndbx-panel-close:hover {
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .sndbx-root {
    position: absolute;
  }
}

@media only screen and (max-width: 950px) {
  .sndbx-root .sv2-spdrn-right-panel {
    position: absolute;
    top: 0;
    right: 0;
    // max-width: 100%;
    width: 100%;
    z-index: 100;
    display: none;
  }
  
  .sndbx-right-panel-toggle {
    display: none;
  }

  .sndbx-panel-close {
    display: flex;
  }
}

.sv2-sndbx-logo {
  width: 30px;
  height: 100%;
  display: inline-block;
}

.sndbx-right-intro {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  line-height: 2;
  color: rgb(190, 190, 190);
}

.sndbx-context-blocks {
  background-color: rgba(255,255, 255, 0.05);
  border-radius: 5px;
  padding: 10px;
  font-family: 'Roboto Mono';
  font-size: 11px;
  margin-bottom: 10px;
  position: relative;
}

.sndbx-context-blocks:last-child {
  margin-bottom: 0;
  .sndbx-context-ctrl {
    margin-left: 8px;
    display: none;
    // float: right;
  }
}

.sndbx-context-blocks:hover {
  cursor: pointer;
  .sndbx-context-ctrl {
    display: inline-block;
  }
  // .sndbx-context-ctrl:hover {
  //   color: rgb(29, 191, 113);
  // }
}

.sndbx-full-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sndbx-online-mark {
  font-size: 8px;
  transition-duration: 0.2s;
  background-color: transparent;
}

.sndbx-online-mark.green {
  color: rgb(29, 191, 113);
  background-color: transparent;
}

.sndbx-context-blocks:hover {
  .sndbx-schedule-delete {
    display: block;
  }
}

.sndbx-schedule-delete {
  display: none;
  width: fit-content;
  line-height: 1;
  padding: 3px 4px;
  border-radius: 3px;
  font-size: 80%;
  background-color: rgba(220, 20, 60, 0.125);
  color: crimson;
  position: absolute;
  top: 10px;
  right: 10px;
}