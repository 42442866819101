.sndbx-intro-root {
  font-size: 32px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  height: 100%;
  text-align: left;
  font-weight: 700;
  display: flex;
  align-items: center;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
  color: rgb(200, 200, 200);
  padding-left: 20px;
}

@media only screen and (max-width: 600px) {
  .sndbx-intro-root {
    padding-left: 0px;
  }
}

.sndbx-intro-msg {
  filter: brightness(80%);
}

.sndbx-intro-root strong {
  filter: brightness(120%);
}

.wrapper-style .sndbx-intro-root {
  padding-left: 5px;
}

.sndbx-intro-icons {
  font-size: 16px;
  margin-top: 5px;
}

.sndbx-intro-example-card {
  line-height: 1.25;
  font-size: 13px;
  font-weight: 400;
  width: 200px;
  padding: 10px;
  border-radius: 7.5px;
  border: 1.5px solid rgb(65, 75, 85);
  // color: rgb(228, 149, 2);
}