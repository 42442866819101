:root {
  --sndbx-auto-fm-url-bg: 255, 255, 255, 0.1;
  --sndbx-auto-fm-url-clr: 167, 202, 232, 1.0;
}

.sndbx-fm-url {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(var(--sndbx-auto-fm-url-bg));
  color: rgba(var(--sndbx-auto-fm-url-clr)) !important;
  padding: 2px 4px !important;
  border-radius: 4px;
  font-weight: 400;
  font-size: 0.85em;
  font-family: 'Roboto Mono';
}
.sndbx-fm-url:visited {
  color: rgba(var(--sndbx-auto-fm-url-clr));
}
.sndbx-fm-url:hover {
  filter: brightness(115%);
}

.sndbx-fm-ellipsis {
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}