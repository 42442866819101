// .file-explorer-modifier {
//   --chonky-grid-file-entry-font-size: 12px !important;
//   --chonky-toolbar-font-size: 20px !important;
//   --chonky-context-menu-font-size: 12px !important;
//   --chonky-breadcrumbs-font-size: 12px !important;
// }

// .file-explorer-modifier .chonky-file-list {
//   font-size: 12px !important;
// }

// .file-explorer-modifier .chonky-file-entry {
//   font-size: 12px !important;
// }

// .file-explorer-modifier .chonky-file-entry-title {
//   font-size: 12px !important;
// }

// .file-explorer-modifier .chonky-file-entry-description {
//   font-size: 12px !important;
// }

// .file-explorer-modifier .chonky-fileEntryClickableWrapper * {
//   font-size: 12px !important;
// }

.file-explorer-modifier {
  --file-browser-color: 190, 190, 190;
  .chonky-iconWithText {
    color: rgb(var(--file-browser-color)) !important;
  }
  .chonky-infoText {
    font-size: 12px !important;
    color: rgb(var(--file-browser-color)) !important;
    text-transform: capitalize;
  }

  .MuiBreadcrumbs-li .MuiButton-text {
    font-size: 12px !important;
    color: rgb(var(--file-browser-color)) !important;
    font-style: italic;
  }

  .chonky-fileListEmptyContent {
    font-size: 14px;
    font-weight: 700;
    opacity: 0.7;
    font-style: italic;
  }
}

.file-explorer-modifier .chonky-fileEntryClickableWrapper {
  [class^="listFileEntry-"] {
    font-size: 12px;
    color: rgb(var(--file-browser-color)) !important;
  }
  [class^="listFileEntryProperty-"] {
    font-size: 12px;
  }
  [class^="selectionIndicator-"] {
    background: rgba(255, 255, 255, 0.15) !important;
  }
  [class^="focusIndicator-"] {
    box-shadow: none !important;
  }
}

.file-explorer-modifier .chonky-toolbarRight {
  .MuiButton-label > span {
    font-size: 13px;
    color: rgb(var(--file-browser-color)) !important;
  }
  .chonky-iconOnlyButton {
    display: none;
  }
}

.file-explorer-modifier input {
  // color: rgb(255, 255, 255) !important;
  color: rgb(var(--file-browser-color)) !important;
  border: none !important;
  font-size: 12px !important;
  background-color: transparent !important;
}

.file-explorer-modifier input::placeholder {
  font-size: 12px !important;
}

.file-explorer-modifier .chonky-searchFieldContainer {
  .MuiOutlinedInput-notchedOutline {
    top: 0px;
  }
}

.file-explorer-modifier .chonky-searchFieldInput:hover {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(255, 255, 255, 0.23) !important;
  }
}

.file-explorer-modifier button {
  line-height: 1 !important;
}

.file-explorer-modifier button:focus {
  border: none !important;
  background-color: transparent !important;
}

.file-explorer-modifier button:hover {
  border: none;
}

.file-explorer-modifier .chonky-chonkyRoot {
  background: transparent !important;
  border: none;
}