.sv2-menu-container {
  width: 250px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  border-radius: 15px;
  background-color: white;
  border: 5px solid rgb(246, 248, 250);
  padding: 10px;
  color: rgb(90, 90, 90);
}

.sv2-menu-container:hover {
  cursor: default;
}

.sv2-menu-item {
  width: 100%;
  border-radius: 10px;
  padding: 12px 10px;
  margin-bottom: 5px;
  background-color: transparent;
  background-color: rgb(246, 248, 250);
  transition: all 0.1s ease-in;
  font-weight: 500;
  font-size: 14px;
}

.sv2-menu-item:hover {
  // background-color: rgb(246, 248, 250);
  cursor: pointer;
  background-color: rgb(90, 90, 90);
  color: white;
}

.sv2-menu-item:last-child {
  margin-bottom: 0;
}

.sv2-menu-section:first-child {
  margin-top: 10px;
}

.sv2-menu-section {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 300;
  padding: 0 10px;
}

.sv2-menu-profile {
  font-size: 16px;
  padding-bottom: 6px;
}

.sv2-menu-profile-text {
  width: 100%;
  line-height: 1.25;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sv2-menu-notification-tag {
  background-color: rgb(140, 140, 140);
  color: white;
  padding: 3px 8px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 700;
}