.img-slider {
  position: relative;
  width: 100%;
  border: none !important;
}
.img-slider .slider-container {
  position: absolute;
  width: 500%;
  top: 0;
  left: -100%;
  overflow: hidden;
  animation: slider 15s infinite;
}
.img-slider .slider-container .slide {
  position: relative;
  width: 20%;
  height: 100%;
  float: left;
  overflow: hidden;
}
.img-slider .slider-container .slide img {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
}

$item-count: 5;

@keyframes slider {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25%) - 22%}, #{$i * 25%} {
      left: calc(-100% * $i);
    }
  }
}