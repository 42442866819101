.sellagen-loader {
  display: inline-block;
  border: 2.5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2.5px solid rgb(23, 145, 86);
  width: 18px;
  height: 18px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}