
@use '../../scss/' as *;
.wrap-box {
    position: relative;
}

.menu-item-addition {
    padding: 15px 10px 15px 25px !important;
    margin: 5px;
    background-color: rgb(246, 248, 250);
    border-radius: 10px;
}

.submenu-item-addition {
    background-color: rgb(246, 248, 250);
    border-radius: 10px;
    margin-bottom: 10px;
}

.submenu-item-addition:last-child {
    margin-bottom: 0;
}

.sub-menu a {
    border-bottom: none !important;
}

.menu-item-addition:nth-child(4) {
    padding-right: 20px !important;
}

.search-button-toggle {
    display: none;
}

.search-button-toggle:hover {
    cursor: pointer;
}

.search-field::placeholder {
    line-height: 1.75 !important;
}

@media only screen and (max-width: 1199px) {
    .search-button-toggle {
        display: block;
    }
}

.mode_switcher {
    background-color: transparent;
}

.mode_switcher:hover {
    cursor: pointer;
}