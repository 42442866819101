.sndbx-banner-container {
  width: 100vw;
  height: 910px;
  // min-height: 910px;
  margin-top: 90px;
  // border: 2px solid red;
  display: flex;
  --outer-color: 26, 29, 34;
  --inner-color: 35, 38, 45;
  --border-color: 255, 255, 255, 0.2;
  --text-color: 210, 210, 210;
  --bg-color: 255, 255, 255;
  // --bg-color: 35, 38, 45;
  // background-color: rgb(46, 50, 60);
  position: relative;
}

.sndbx-banner-top-layer {
  width: 100%;
  height: 100%;
  z-index: 50;
  position: absolute;
  // display: flex;
  // align-items: center;
}

.banner-msg {
  width: 100%;
  max-width: calc(100% - 420px);
  padding-left: 100px;
  padding-top: 100px;
}

.banner-msg .flat-slider {
  height: 450px !important;
  // background-color: red;
}

.front-sndbx-wrapper {
  width: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: rgb(var(--theme-nelima-dark-val));
}

.supported-by-badge {
  width: fit-content;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
  font-weight: 400;
  color: rgb(70, 70, 70);
}

.inception-badge {
  margin-left: 20px;
  width: 250px;
}

.banner-stats {
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
  display: flex;
  margin-top: 70px;
}

.banner-unit-stat {
  margin-right: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) {
  .banner-msg {
    padding-left: 50px;
  }
  .banner-unit-stat {
    margin-right: 50px;
  }

  .supported-by-badge {
    font-size: 14px;
    margin-top: 25px;
  }

  .inception-badge {
    margin-left: 10px;
    width: 150px;
  }
}

@media only screen and (max-width: 1100px) {
  .sndbx-banner-container {
    // background-color: red;
    height: 625px !important;
  }

  .banner-msg {
    max-width: 100%;
  }

  .banner-stats {
    display: none;
  }

  .supported-by-badge {
    font-size: 14px;
    margin-top: -25px;
  }

  .inception-badge {
    margin-left: 10px;
    width: 150px;
  }

  .banner-visuals {
    display: none;
  }

  .sndbx-banner-top-layer {
    height: 800px;
  }

  .front-sndbx-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 750px) {
  .banner-msg {
    padding: 10px;
  }

  .banner-msg .sub-heading {
    font-size: 16px !important;
  }

  .banner-msg .flat-slider {
    // height: 650px !important;
    height: fit-content !important;
  }

  .sndbx-banner-container {
    height: 600px !important;
  }

  .sndbx-banner-top-layer {
    height: 600px !important;
  }

  .supported-by-badge {
    font-size: 14px;
    margin-top: 25px;
  }

  .inception-badge {
    margin-left: 10px;
    width: 150px;
  }
}

@media only screen and (max-width: 425px) {
  .sndbx-banner-container {
    height: 700px !important;
  }

  .sndbx-banner-top-layer {
    height: 700px !important;
  }
}

.banner-visuals {
  width: 100%;
  height: 100%;
  background-color: rgb(var(--outer-color));
  position: relative;
  // overflow: hidden;
  z-index: 3;
}

.banner-overlay-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


.banner-overlay-container {
  .cut0 {
    width: 500px;
    height: 500px;
    position: absolute;
    top: -350px;
    right: 0;
    // background-color: red;
    background-color: rgb(var(--bg-color));
    border-radius: 0 0 80px 0;
    z-index: 30;
  }
  .cut1 {
    position: absolute;
    top: calc(150px - 40px);
    right: 200px;
    box-sizing: content-box !important;
    float: right;
    width: 150px;
    height: 230px;
    z-index: 20;
    // background-color: red;
    border-radius: 120px 0 0 0;
    // border-left: 40px solid green;
    // border-top: 40px solid green;
    border-left: 40px solid rgb(var(--bg-color));
    border-top: 40px solid rgb(var(--bg-color));
    .cut1-sub {
      position: absolute;
      bottom: -150px;
      left: calc(-100%);
      width: 150px;
      height: 200px;
      // background-color: purple;
      // background-color: white;
      border-radius: 0 0 40px 0;
      .cut1-sub-fill {
        position: absolute;
        // background-color: blue;
        background-color: rgb(var(--bg-color));
        width: 100vw;
        height: 800px;
        bottom: 0;
        right: 0px;
        border-radius: 0 0 80px 0;
      }
      .cut1-sub-sub {
        position: absolute;
        bottom: calc(-100% + 50px);
        left: -200px;
        width: 150px;
        height: 150px;
        box-sizing: content-box !important;
        border-radius: 120px 0 0 0;
        // border-left: 40px solid orange;
        // border-top: 40px solid orange;
        border-left: 40px solid rgb(var(--bg-color));
        border-top: 40px solid rgb(var(--bg-color));
        .cut-sub-sub-fill {
          // background-color: indianred;
          background-color: rgb(var(--bg-color));
          position: absolute;
          width: 100vw;
          height: 300px;
          right: 100%;
          bottom: 0;
        }
        .cut1-sub-sub-sub {
          position: absolute;
          // background-color: fuchsia;
          background-color: rgb(var(--bg-color));
          width: 100px;
          height: 230px;
          left: calc(-100% + 50px);
          top: 150px;
          border-radius: 0 0 80px 0;
        }
        .cut1-sub-sub-sub-fill {
          position: absolute;
          // background-color: teal;
          background-color: rgb(var(--bg-color));
          width: 100vw;
          height: 100%;
          bottom: 0;
          right: 100%;
        }
      }
    }
  }
}

.banner-overlay-container {
  .cut0-shadow {
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
    height: 150px;
    // background-color: blue;
    z-index: 10;
    border-radius: 0 0 80px 0;
    box-shadow: 0px 50px 100px -10px rgba(0, 0, 0, 0.4);
  }
  .cut1-shadow {
    position: absolute;
    top: 150px;
    // left: 10px;
    right: 350px;
    width: 400px;
    height: 380px;
    // background-color: red;
    z-index: 10;
    box-shadow: 50px 50px 100px -10px rgba(0, 0, 0, 0.4);
    border-radius: 0 0 80px 0;
  }
  .cut2-sub-shadow {
    position: absolute;
    top: 510px;
    right: 660px;
    width: 100vw;
    height: 400px;
    // background-color: green;
    z-index: 10;
    border-radius: 0 0 80px 0;
    box-shadow: 0px 50px 100px -10px rgba(0, 0, 0, 0.4);
  }
}

.visiual-overlay-base {
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
}

.bnr-ovl {
  width: 1200px;
  height: 1200px;
  z-index: 10;
  border: 100px solid rgba(255, 255, 255, 1);
  box-shadow: inset 0 0 1000px 5px rgba(255, 255, 255, 0.5);
}

.bnr-ovl-end {
  width: 2000px;
  height: 2000px;
  z-index: 9;
  border: 450px solid rgba(255, 255, 255, 1);
}


// carousel

.banner-carousel-container {
  width: 800px;
  height: 800px;
  background-color: transparent;
  position: absolute;
  top: 150px;
  right: 0;
  z-index: 0;
  padding-bottom: 30px;
}

.banner-visuals .swiper-container {
  z-index: 10;
  height: 380px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  -webkit-transition-timing-function:linear!important; 
  -o-transition-timing-function:linear!important;
  transition-timing-function:linear!important;
}

.banner-visuals .swiper-container * {
  -webkit-transition-timing-function:linear!important; 
  -o-transition-timing-function:linear!important;
  transition-timing-function:linear!important;
}

.banner-visuals .swiper-slide {
  display: flex;
  min-width: 380px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
}

.banner-example-block {
  position: relative;
  box-sizing: border-box;
  background-color: rgb(var(--inner-color));
  border: 1px solid rgb(var(--border-color));
  border-radius: 25px;
  width: 350px;
  height: 350px;
  color: rgb(var(--text-color));
  padding: 20px;
}

.banner-example-block .msg-bubble {
  // background-color: rgb(66, 125, 235);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 500;
  max-width: 220px;
  border-radius: 30px;
  color: white;
  line-height: 1.5;
}

.banner-example-block .placeholder-text {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: 2px;
  width: 97%;
  height: 4px;
  margin-bottom: 9px;
}

.banner-example-block .dark {
  background-color: rgba(0, 0, 0, 0.2);
}

.banner-example-block .result-holder {
  border-radius: 15px;
  position: relative;
  border: 1px solid rgb(var(--border-color));
  width: 100%;
  height: 230px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
}
.result-holder .grid {
  width: 200%;
  height: 200%;
  position: absolute;
  top: -40px;
  left: -10px;
  background-size: 40px 40px;
  background-image:
    linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  z-index: 5;
}

.banner-example-block .bars {
  display: inline-block;
  width: 5px;
  border-radius: 2px;
  height: 90%;
  background-color: #40A2D8;
  margin-left: 4px;
}

.banner-example-block .bars-g {
  background-color: #D63484;
}

.banner-example-block .bars:last-child {
  margin-right: 0;
}

.banner-example-block .y-label {
  position: absolute;
  transform: rotate(-90deg) translate(50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 220px;
  left: -3px;
}

.banner-example-block .map-holder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.map-holder .map-image {
  scale: 1.5;
  width: 100%;
  height: auto;
}

.banner-example-block .sv2-table {
  width: auto;
  table-layout: auto;
  border-collapse: collapse;
  margin-bottom: 0;
  font-size: 16px;
}

.banner-example-block .sv2-table-head * {
  font-weight: bold;
}

.banner-example-block .sv2-table-head th, .banner-example-block .sv2-table-body td {
  text-align: right;
}

.banner-example-block .sv2-table td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.banner-example-block .sv2-table td {
  border: 0.5px solid rgb(55, 60, 65);
}

.banner-example-block .sv2-table-head tr > * {
  background-color: rgba(255, 255, 255, 0.055) !important;
}

.banner-example-block .sv2-table tr:nth-child(even) > td:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.025);
}

.banner-example-block .sv2-table tr:nth-child(odd) > td:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.025);
}

.banner-example-block .ide {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgb(39, 44, 53);
  border: 1px solid rgb(var(--border-color));
  padding: 10px;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
  font-size: 16px;
  line-height: 1.5;
  color: rgb(157, 155, 151);
}

.ide .func {
  color: rgb(97, 176, 239);
}

.ide .def {
  color: rgb(198, 121, 221);
}

.ide .prop {
  color: rgb(171, 178, 192);
}

.ide .int {
  color: rgb(209, 154, 102);
}

.ide .str {
  color: rgb(152, 195, 121);
}
