@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');

.sv2-api-bnr-blk1 { 
  width: 45%;
  background-color: rgb(246, 248, 250);
  border-radius: 10px;
  padding: 20px;
}
.sv2-api-bnr-blk2 { 
  position: relative;
  overflow: hidden;
  flex: 1;
  background-color: var(--sv2-ide-background);
  color: var(--sv2-ide-color);
  font-size: 16px;
  line-height: 1.6;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace;
  box-shadow: none;
  font-weight: 400;
  border-radius: 10px;
  margin-left: 20px;
  padding: 40px 15px 10px 15px;
  word-break: break-all;
  min-height: 200px;
  // user-select: none;
}

.sv2-api-banner {
  width: 100%;
  max-width: 1150px;
  margin: auto;
  margin-top: 170px;
  // height: 300px;
  border-radius: 20px;
  padding: 20px;
  border: 4px solid rgb(246, 248, 250);
  display: flex;
}

@media only screen and (max-width: 800px) {
  .sv2-api-banner {
    width: calc(100% - 10px);
    flex-direction: column;
    padding: 15px;
  }
  .sv2-api-bnr-blk1 { 
    width: 100%;
  }
  .sv2-api-bnr-blk2 {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}

.sv2-api-get-started {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--sv2-sellagen);
  background-color: var(--sv2-ide-background);
  width: fit-content;
  border-radius: 8px;
}

.sv2-api-get-started:hover {
  cursor: pointer;
}

.sv2-code-blk-tab {
  background-color: #1a1d22;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 700;
}

.sv2-code-cursor-container {
  display: inline-block;
  position: relative;
  height: 18px;
  width: 10px;
  margin-left: 0px;
}

.sv2-code-cursor {
  position: absolute;
  top: 3.5px;
  left: 0;
  height: 18px;
  width: 10px;
  background-color: var(--sv2-ide-color);
  animation: blinker steps(1) 500ms infinite alternate;
  user-select: none;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}