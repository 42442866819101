.customAddButton {
  height: 196.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(246, 248, 250);
  border: 2px solid rgb(240, 242, 244);
  border-radius: 10px;
  color: rgb(210, 210, 210);
}

.customAddButton:hover {
  cursor: pointer;
}

.customSwiper {
  padding: 20px !important;
  background-color: red !important;
}

.swiperHover {
  cursor: initial;
}

.swiperHover:hover {
  cursor: pointer;
}

.swiper-container::after {
  background-color: white;
  content: ' ';
  position: absolute;
  right: calc(100% + 5px);
  top: 0;
  width: 100%;
  z-index: 100;
  height: calc(100% + 10px);
}

.swiper-container::before {
  background-color: white;
  content: ' ';
  position: absolute;
  left: calc(100% + 5px);
  top: 0;
  z-index: 100;
  width: 100%;
  height: calc(100% + 10px);
}