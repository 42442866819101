.sellagen-tag {
  padding: 2px 10px;
  font-size: 11px;
  border-radius: 5px;
  font-weight: 500;
}

.green {
  background-color: rgb(215, 247, 194);
  color: rgb(1, 105, 7);
}

.yellow {
  background-color: rgb(255, 230, 132);
  color: rgb(140, 107, 0);
}

.red {
  background-color: rgb(249, 160, 155);
  color: rgb(161, 6, 5);
}

.grey {
  background-color: rgb(236, 238, 240);
  color: rgb(126, 128, 130);
}

.purple {
  background-color: rgb(237, 231, 246);
  color: rgb(49, 27, 146);
}

.with-link {
  cursor: pointer;
}

.sellagen-logout {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.sellagen-logout:hover {
  cursor: pointer;
  color: rgb(29, 191, 113);
}