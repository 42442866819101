.imgContainer {
  display: block;
  width: 150px;
  height: 150px;
  /* background-color: red; */
}

.container {
  max-width: 93.5rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.btn {
  display: inline-block;
  font: inherit;
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}

.btn:focus {
  outline: 0.5rem auto #4d90fe;
}

.visuallyHidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Profile Section */

.profile {
  padding: 5rem 0;
}

.profile::after {
  content: "";
  display: block;
  clear: both;
}

.profileImage {
  float: left;
  width: calc(33.333% - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
}

/* .profileImage .imgContainer {
  border-radius: 50%;
} */

.profileUserSettings,
.profileStats,
.profileBio {
  float: left;
  width: calc(66.666% - 2rem);
}

.profileUserSettings {
  margin-top: 1.1rem;
}

.profileUserName {
  display: inline-block;
  font-size: 3.2rem;
  font-weight: 300;
}

.profileEditBtn {
  font-size: 1.4rem;
  line-height: 1.8;
  border: 0.1rem solid #dbdbdb;
  border-radius: 0.3rem;
  padding: 0 2.4rem;
  margin-left: 2rem;
}

.profileSettingsBtn {
  font-size: 2rem;
  margin-left: 1rem;
}

.profileStats {
  margin-top: 2.3rem;
}

.profileStats li {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-right: 4rem;
  cursor: pointer;
}

.profileStats li:last-of-type {
  margin-right: 0;
}

.profileBio {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 2.3rem;
}

.profileRealName,
.profileStatCount,
.profileEditBtn {
  font-weight: 600;
}

/* Gallery Section */

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1rem;
  padding-bottom: 3rem;
}

.galleryItem {
  position: relative;
  flex: 1 0 22rem;
  margin: 1rem;
  color: #fff;
  cursor: pointer;
}

.galleryItem:hover .galleryItemInfo,
.galleryItem:focus .galleryItemInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.galleryItemInfo {
  display: none;
}

.galleryItemInfo li {
  display: inline-block;
  font-size: 1.7rem;
  font-weight: 600;
}

.galleryItemLikes {
  margin-right: 2.2rem;
}

.galleryItemType {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.fa-clone,
.fa-comment {
  transform: rotateY(180deg);
}

.galleryImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Loader */

.loader {
  width: 5rem;
  height: 5rem;
  border: 0.6rem solid #999;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: 0 auto;
  animation: loader 500ms linear infinite;
}

/* Media Query */

@media screen and (max-width: 40rem) {
  .profile {
      display: flex;
      flex-wrap: wrap;
      padding: 4rem 0;
  }

  .profile::after {
      display: none;
  }

  .profileImage,
  .profileUserSettings,
  .profileBio,
  .profileStats {
      float: none;
      width: auto;
  }

  .profileImage .imgContainer {
      width: 7.7rem;
      height: 7.7rem;
  }

  .profileUserSettings {
      flex-basis: calc(100% - 10.7rem);
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
  }

  .profileUserName {
      font-size: 2.2rem;
  }

  .profileEditBtn {
      order: 1;
      padding: 0;
      text-align: center;
      margin-top: 1rem;
  }

  .profileEditBtn {
      margin-left: 0;
  }

  .profileBio {
      font-size: 1.4rem;
      margin-top: 1.5rem;
  }

  .profileEditBtn,
  .profileBio,
  .profileStats {
      flex-basis: 100%;
  }

  .profileStats {
      order: 1;
      margin-top: 1.5rem;
  }

  .profileStats ul {
      display: flex;
      text-align: center;
      padding: 1.2rem 0;
      border-top: 0.1rem solid #dadada;
      border-bottom: 0.1rem solid #dadada;
  }

  .profileStats li {
      font-size: 1.4rem;
      flex: 1;
      margin: 0;
  }

  .profileStatCount {
      display: block;
  }
}

/* Spinner Animation */

@keyframes loader {
  to {
      transform: rotate(360deg);
  }
}

/*

The following code will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox & floated styling. 

*/

@supports (display: grid) {
  .profile {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: repeat(3, auto);
      grid-column-gap: 3rem;
      align-items: center;
  }

  .profileImage {
      grid-row: 1 / -1;
  }

  .gallery {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
      grid-gap: 2rem;
  }

  .profileImage,
  .profileUserSettings,
  .profileStats,
  .profileBio,
  .galleryItem,
  .gallery {
      width: auto;
      margin: 0;
  }

  @media (max-width: 40rem) {
      .profile {
          grid-template-columns: auto 1fr;
          grid-row-gap: 1.5rem;
      }

      .profileImage {
          grid-row: 1 / 2;
      }

      .profileUserSettings {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 1rem;
      }

      .profileEditBtn,
      .profileStats,
      .profileBio {
          grid-column: 1 / -1;
      }

      .profileUserSettings,
      .profileEditBtn,
      .profileSettingsBtn,
      .profileBio,
      .profileStats {
          margin: 0;
      }
  }
}

.sellagenProfileInput {
  border-radius: 10px;
  background-color: rgb(246, 248, 250);
  border: none;
  color: rgb(70, 70, 70);
  outline: none;
}

.miniIp {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  margin: 5px 0;
}