.sv2-cloud-base-manager {
  --theme-color-val: 190, 190, 190;
  --theme-color: rgb(var(--theme-color-val));
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: var(--sub-panel-background);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 5px;
}

.sv2-cloud-loading {
  min-width: 300px;
  min-height: 50px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--theme-main2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-color);
}

.base-projects-page {
  width: 100vw !important;
  height: 100vh !important;
  background-color: var(--theme-main) !important;
  border-radius: 0;
  color: var(--theme-color);
  overflow: scroll !important;
}

.sv2-prj-manager-container {
  max-width: 600px;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  max-height: fit-content;
  margin: auto;
  background-color: var(--theme-main4);
}

.sv2-prj-search-input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--theme-main);
  border: none;
  outline: none;
  max-width: 200px;
  border-radius: 10px;
  color: var(--theme-color);
  padding: 5px 10px;
}

.sv2-manager-subblock {
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
}

.sv2-manager-subblock:last-child {
  margin-bottom: 0;
}

.sv2-manager-title {
  width: 100%;
  height: 50px;
  font-size: 18px;
  color: var(--theme-color);
  font-weight: 700;
  background-color: var(--theme-main2);
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 5px;
}

.base-content {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  background-color: var(--theme-main2);
  color: var(--theme-color);
}

.base-add-ds-btn {
  background-color: rgb(26, 29, 34) !important;
  font-size: 14px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: color 0.2s;
  border-radius: 10px;
  margin-top: 10px !important;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.base-add-ds-btn:hover {
  cursor: pointer;
  background-color: var(--theme-main5);
  color: rgb(29, 191, 113);
}

.sv2-base-content-title {
  font-size: 14px;
  font-weight: 700;
  margin: 5px 10px 10px 10px;
}

.sv2-manager-data-list {
  width: 100%;
  border-radius: 5px;
  background-color: var(--theme-main2);
  display: flex;
  flex-direction: column;
}

.sv2-manager-empty-list {
  width: 100%;
  height: 100px;
  margin-bottom: auto;
  color: rgb(80, 80, 80);
  background-color: var(--sub-panel-background);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.sv2-manager-submit-btn {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--theme-main4);
  padding: 10px;
  border-radius: 5px;
  border: none !important;
}

.btn-alt {
  background-color: var(--theme-main3);
}

.sv2-manager-submit-btn:hover {
  cursor: pointer;
  color: var(--theme-main4);
}

.sv2-manager-submit-btn:disabled {
  cursor: default;
  color: var(--theme-color);
  background-color: var(--theme-main4);
  border: none !important;
  filter: brightness(70%);
}

.sv2-manager-submit-btn:disabled .sv2-manager-submit-btn:hover {
  cursor: default;
  color: var(--theme-color);
  background-color: var(--theme-main4);
  border: none !important;
}

.sv2-manager-corner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: var(--theme-color);
  background-color: transparent;
}

.sv2-manager-corner-btn:hover {
  cursor: pointer;
  filter: brightness(120%);
}

.sv2-manager-search-card {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--theme-main);
  color: var(--theme-color);
  position: relative;
  margin-bottom: 5px;
}

.sv2-mgr-dc-ellipsis {
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 130px;
  padding-bottom: 1px;
}

.sv2-prj-search-input {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  background-color: var(--theme-main4);
  padding-left: 10px;
  padding-right: 40px;
  border-radius: 5px;
  caret-color: var(--theme-color);
  color: var(--theme-color);
}

.sv2-manager-data-card {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--theme-main);
  color: var(--theme-color);
  margin-bottom: 5px;
  position: relative;
  z-index: 2;
}

.sv2-manager-data-card:hover {
  background-color: rgb(48, 53, 62);
  cursor: pointer;
  z-index: 16;
}

.sv2-manager-data-card:last-child {
  margin-bottom: 0;
}

.sv2-dc-no-hov:hover {
  background-color: var(--theme-main);
  cursor: default;
  z-index: 2;
}

.sv2-mgr-dc-title {
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  width: 350px;
  height: 20px;
}
.sv2-mgr-dc-body {
  color: rgba(var(--theme-color-val), 0.7);
  margin-top: 10px;
  font-size: 11px;
  font-weight: 500;
  width: 350px;
}

.sv2-mgr-comp-action {
  float: right;
  font-size: 11px;
  line-height: 1.5;
  opacity: 0;
  background-color: var(--theme-main3);
  padding: 2px 5px;
  border-radius: 3px;
}

.sv2-manager-data-card:hover .sv2-mgr-comp-action{
  opacity: 1;
}

.sv2-mgr-dc-add-btn-container {
  height: 100%;
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sv2-mgr-dc-add-btn {
  --sv2-mgr-dc-btn-color: 29, 191, 113;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  font-size: 18px;
  background-color: rgba(29, 191, 113, 0.2);
  color: rgb(29, 191, 113);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.sv2-mgr-dc-add-btn-lic {
  --sv2-mgr-dc-btn-color: 255, 165, 0;
  background-color: rgba(255, 165, 0, 0.2);
  color: rgb(255, 165, 0);
  position: relative;
}

.sv2-mgr-dc-add-btn-refresh {
  background-color: rgba(29, 191, 113, 0.2);
  color: rgb(29, 191, 113);
  position: relative;
}

.sv2-mgr-dc-add-btn:hover {
  cursor: pointer;
}

.sv2-mgr-dc-purchase-info {
  display: none;
  position: absolute;
  right: calc(100% + 5px);
  background-color: var(--theme-main5);
  border-radius: 5px;
  border: 0.5px solid rgba(255, 165, 0, 0.8);
  color: var(--theme-color);
  flex-direction: row;
  z-index: 15;
}

.sv2-mgr-dc-purchase-info-sub {
  width: 230px;
  min-height: 100px;
  font-size: 10px;
  padding: 10px 10px 10px 0;
  line-height: 1.35;
  z-index: 15;
}

.sv2-mgr-dc-add-btn-lic:hover .sv2-mgr-dc-purchase-info {
  z-index: 20;
  display: flex;
}

.sv2-mgr-dc-add-btn-refresh:hover .sv2-mgr-dc-purchase-info {
  z-index: 20;
  display: flex;
}

.sv2-mgr-ds-search {
  height: 40px;
  width: 100%;
  position: relative;
}

.sv2-spdrn-system-panel {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.sv2-spdrn-system-mini {
  position: relative;
  padding: 2px 10px 0px 10px;
}

.sv2-spdrn-system-panel > .system {
  position: relative;
  border-radius: 5px;
  width: 100%;
  background-color: var(--theme-main);
  --sub-panel-background: var(--theme-main);
}

.sv2-spdrn-system-init {
  position: relative;
  border-radius: 5px;
  width: 100%;
  background-color: var(--theme-main);
  --sub-panel-background: var(--theme-main);
}

.sv2-system-panel-control {
  position: absolute;
  height: 50px;
  margin-right: 15px;
  padding: 0 5px;
  top: 0;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  z-index: 100;
}

.sv2-system-panel-control:hover {
  cursor: pointer;
}

.sv2-spdrn-system-mini > .system {
  position: relative;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-color);
  height: 48px;
  border-radius: 8px;
  background-color: var(--theme-main5);
  --sub-panel-background: var(--theme-main5);
}

.sv2-panel-mini {
  height: 48px;
  right: 0px;
}

// .sv2-go-back {
//   color: rgb(100, 100, 100);
//   font-weight: 500;
//   margin: 10px 0;
//   padding: 5px 7px;
//   width: max-content;
//   border-radius: 5px;
//   background-color: var(--theme-main);
// }

.sv2-go-back:hover {
  cursor: pointer;
  color: rgb(29, 191, 113);
}

.sv2-manager-filename {
  padding: 2px 7px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 1;
  z-index: 1;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace;
}

.sv2-manager-instance-details {
  width: 100px;
  display: inline-block;
}


.sv2-mgr-ds-search-alt {
  border-radius: 3px;
  background-color: var(--theme-main2);
}

.sv2-mgr-ds-search-alt > .sv2-mgr-ds-search-input {
  font-size: 12px;
  font-style: italic;
}

.sv2-mgr-ds-search-input {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding-left: 10px;
  padding-right: 40px;
  border-radius: 5px;
  caret-color: var(--theme-color);
  color: var(--theme-color);
}

.sv2-mgr-ds-search-input::placeholder {
  font-weight: 500;
  color: var(--theme-color);
  filter: brightness(70%);
  line-height: 1.5;
}

.sv2-mgr-ds-search-icon {
  font-size: 18px;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sv2-mgr-ds-search-icon:hover {
  cursor: pointer;
}

.sv2-ai-popup {
  width: 400px;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
}

.sv2-ai-popup-content {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--theme-main);
  // color: var(--theme-color);
  font-weight: 500;
  color: rgba(var(--theme-color-val), 0.7);
  font-size: 12px;
}

.sv2-ai-popup-bottom-thingy {
  border-top: 7.5px solid var(--theme-main2);
  border-right: 7.5px solid transparent;
  border-left: 7.5px solid transparent;
  margin-left: 5px;
  position: absolute;
  bottom: -7.5px;
  font-size: 0;
  content: ' ';
}

.sv2-cloud-misc-action-button {
  border-radius: 4px;
  background-color: var(--theme-main4);
  font-size: 11px;
  padding: 3px 6px;
  line-height: 1.0;
  font-weight: 500;
  display: inline-block;
}

.sv2-cloud-misc-action-button:hover {
  cursor: pointer;
  filter: brightness(110%);
}

.misc-alt {
  background-color: var(--theme-main2);
  color: salmon;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important; 
}

.sv2-dc-serve-file {
  background-color: var(--theme-main3);
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
}

.sv2-dc-basic-tag {
  display: inline-block;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--theme-main5);
  color: var(--theme-color);
}

.sv2-dc-basic-tag:hover {
  cursor: pointer;
}

.sv2-endpoint-viewer {
  display: inline-block;
  padding: 2px 6px;
  background-color: var(--theme-main5);
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  color: var(--theme-color);
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
}

.sv2-endpoint-viewer:hover {
  cursor: pointer;
}

.sv2-cloud-info-tooltip-container {
  position: relative;
  cursor: pointer;
}

// TODO: remove fixed colors
.sv2-cloud-info-tooltip-body {
  display: none;
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(48, 53, 62);
  font-size: 10.5px;
  font-weight: 500;
  line-height: 1.3;
  width: max-content;
  max-width: 210px;
  z-index: 100;
  border: 2px solid rgb(75, 80, 90);
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
}

.sv2-cloud-info-tooltip-container > .top {
  top: unset;
  bottom: calc(100% + 5px);
}

.sv2-cloud-info-tooltip-container:hover > .sv2-cloud-info-tooltip-body {
  display: block;
}

// TODO: remove fixed colors
.sv2-cloud-dropdown {
  display: inline-flex;
  flex-direction: column;
  // width: 100px;
  overflow: hidden;
  border-radius: 3px;
}

.sv2-cloud-dropdown > .block {
  width: 200px;
  background-color: rgb(26, 29, 34);
  color: rgb(190, 190, 190);
  padding: 2px 5px;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sv2-cloud-dropdown > .option {
  text-align: right;
}

.sv2-cloud-dropdown > .option:hover {
  color: rgb(29, 191, 113);
  background-color: rgb(34, 38, 45);
}