.sv2-tooltip {
  position: relative;
  display: inline-block;
}

.sv2-tooltip .sv2-tooltiptext {
  visibility: hidden;
  // background-color: rgb(230, 232, 234);
  background-color: rgb(246, 248, 250);
  color: rgb(90, 90, 90);
  // box-shadow: -2px 6px 23px #b4b4b44f;
  line-height: 1.25;
  font-size: 12px;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  // border: solid 5px white;
  // margin-left: -60px;
  opacity: 0;
  transition: opacity 0s ease-out;
  transition-delay: 0.5s;
}

.sv2-tooltip-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0px;
  overflow: visible;
  display: flex;
  justify-content: center;
}

// .sv2-tooltiptext::after {
//   content: "";
//   position: absolute;
//   top: calc(100% + 5px);
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: white transparent transparent transparent;
// }

.sv2-tooltip:hover .sv2-tooltiptext {
  visibility: visible;
  opacity: 1;
}