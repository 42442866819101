*{--search-color: rgb(230, 230, 230);}

.dummySearchBar {
	width: 550px;
	padding-bottom: 2px;
	// border-bottom: 3px solid var(--search-color);
	user-select: none;
	cursor: default;
}

.wrapper{
  display: flex;
	box-sizing: border-box;
	overflow: hidden;
	height: 45px;
	font-size: 28px;
}
.wrapper .static-txt{
	color: var(--search-color);
	font-weight: 500;
	box-sizing: border-box;
	height: 60px;
	padding-top: 5px;
}

.wrapper .dynamic-txts{
  margin-left: 10px;
	box-sizing: border-box;
	height: 60px;
}

.dynamic-txts li{
  list-style: none;
  color: var(--search-color);
  // font-size: 30px;
  font-weight: 500;
	box-sizing: border-box;
  position: relative;
  bottom: 0;
	overflow: hidden;
	height: 60px;
	padding-top: 5px;
  animation: slide 12s steps(4) infinite;
	animation-delay: 0.5s;
}

@keyframes slide {
  100%{
    bottom: 240px;
  }
}
.dynamic-txts li span{
  position: relative;
	box-sizing: border-box;
}
.dynamic-txts li span::after{
	box-sizing: border-box;
  content: "";
  position: absolute;
  left: 0;
	top: -2px;
  height: 100%;
  width: 100%;
	background-color: white;
  border-left: 3px solid var(--search-color);
  animation: typing 3s steps(15) infinite;
	animation-delay: 0.5s;
}
@keyframes typing {
  40%, 60%{
    left: 100%;
  }
  100%{
    left: 0;
  }
}