.sv2-prvw-root {
  width: 100%;
  // height: 200px;
  // border-radius: 5px;
  // overflow: hidden;
  // margin-top: 10px;
  // margin-bottom: 10px;
  // background-color: var(--theme-main3);
  // padding: 10px;
  // line-height: 1;
}