@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');
:root {
  --sv2-spdrn-task-btn-clr: #202228;
}

.sv2-spdrn-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: var(--theme-main);
  color: white;
}

.sv2-spdrn-sidemenu {
  overflow: hidden;
  width: 70px;
  height: 100%;
  display: none;
}

.sv2-spdrn-body {
  position: relative;
  width: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sv2-spdrn-toptool {
  width: 100%;
  height: 60px;
  overflow: hidden;
  background-color: var(--theme-main2);
  display: flex;
}

.sv2-spdrn-logo {
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
}

.sv2-spdrn-title {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 700;
  color: var(--theme-color);
  position: relative;
}

.sv2-spdrn-save-btn {
  padding: 3px 6px;
  font-weight: 500;
  font-size: 10px;
  background-color: var(--theme-main4);
  color: rgb(150, 150, 150);
  border-radius: 3px;
}

.sv2-spdrn-save-btn:hover {
  cursor: pointer;
  filter: brightness(110%);
}

.sv2-spdrn-subtitle {
  position: absolute;
  bottom: 8px;
  left: 5px;
  font-size: 9px;
  font-weight: 500;
  opacity: 0.5;
}

.sv2-spdrn-main-view {
  overflow: hidden;
  width: 100%;
  flex: 1;
  display: flex;
}

.sv2-spdrn-left-panel {
  overflow: hidden;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sv2-spdrn-right-panel {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 500px;
  background-color: var(--theme-main3);
  // padding: 20px 20px;
}

.sv2-spdrn-run-manager {
  overflow: hidden;
  background-color: var(--theme-main4);
  width: 100%;
  height: 30px;
  display: none;
}

.sv2-spdrn-task-panel {
  overflow: hidden;
  position: relative;
  background-color: var(--theme-main3);
  width: 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.sv2-spdrn-tasks {
  overflow: hidden;
  background-color: var(--theme-main5);
  // border: 1px solid white;
  border-radius: 45px;
  height: fit-content;
  padding: 0 10px;
}

.sv2-spdrn-task-unit {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin: 25px 0;
  color: var(--theme-main5);
  transition: box-shadow 0.5s;
}

.sv2-spdrn-taskbar-line {
  width: 100%;
  margin: -2px 0;
  border: 1px solid var(--theme-main);
  border-radius: 5px;
}

.sv2-spdrn-task-unit:first-child {
  margin-top: 10px;
}

.sv2-spdrn-task-unit {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 0px var(--sv2-spdrn-task-btn-clr);
  }
}

.sv2-spdrn-task-unit:last-child {
  margin-bottom: 10px;
}

.sv2-spdrn-code-panel {
  overflow: hidden;
  flex: 1;
  width: 100%;
  position: relative;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace;
}

.sv2-spdrn-code-filename {
  padding: 5px 10px;
  font-size: 12px;
  background-color: var(--theme-main2);
  border-radius: 10px;
  position: absolute;
  top: 3px;
  right: 3px;
  opacity: 0.7;
  line-height: 1;
  z-index: 1;
}

.sv2-spdrn-pre-block {
  border-radius: 0 !important;
  height: 100%;
  background-color: transparent;
}

.sv2-spdrn-code-block {
  background-color: transparent;
  height: 100%;
  padding: 0 !important;
}

.sv2-codemirror-configure {
  height: 100% !important;
  font-size: 14px;
}

.sv2-codemirror-mini-configure {
  height: 100% !important;
  font-size: 12px;
}

.sv2-codemirror-mini-configure > * {
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
}

.sv2-codemirror-configure > * {
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
}

.cm-editor {
  padding-bottom: 60px;
}

.simple-editor {
  // padding: 10px;
  width: 100%;
  max-width: 850px;
  // height: calc(100% - 145px);
  height: 100%;
  max-height: 600px;
  // margin: auto;
  margin: auto;
  overflow: hidden;
  border-radius: 20px;
  border: 10px solid transparent;
}

.auth-storage-manager-container {
  width: 100%;
  max-width: 850px;
  // height: calc(100% - 145px);
  height: 100%;
  max-height: 600px;
  // margin: auto;
  margin: auto;
  overflow: hidden;
  border-radius: 20px;
  border: 10px solid transparent;
}

.auth-storage-manager {
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
  width: 100%;
  height: 100%;
  background-color: var(--theme-main2);
  // padding: 15px;
  line-height: 1.25;
  color: rgb(200, 200, 200);
  display: flex;
  flex-direction: column;
}

.simple-editor .cm-editor {
  padding-bottom: 0px !important;
}

.cm-scroller {
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
}

.cm-tooltip {
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
}

.cm-activeLine {
  background-color: rgba(255, 255, 255, 0.03) !important;
}

.cm-activeLineGutter {
  background-color: rgba(255, 255, 255, 0.03) !important;
}

.cm-foldPlaceholder {
  padding: 0 3px 0 1px !important;
  background-color: rgba(255, 255, 255, 0.15) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  // color: rgba(255, 255, 255, 1) !important;
  color: rgb(200, 200, 200) !important;
}

.sv2-spdrn-ai-panel-container {
  width: 100%;
  max-width: 850px;
  margin: auto;
  z-index: 2;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  padding: 10px;
  font-size: 14px;
}

.sv2-spdrn-ai-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(29, 191, 113);
  transition: text-shadow 1s;
}

.sv2-spdrn-ai-icon > .glow {
  text-shadow: 0 0 10px rgb(29, 191, 113);
}

.sv2-spdrn-ai-input-form {
  width: 100%;
  display: flex;
  background-color: var(--theme-main2);
  // background-color: rgb(45, 50, 60);
  border-radius: 8px;
  padding: 4px 0;
  position: relative;
}

.sv2-spdrn-ai-input-div {
  width: calc(100% - 40px);
  // min-height: 50px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  max-height: 250px;
  overflow-y: auto;
  scroll-behavior: smooth;
  background-color: transparent;
  position: relative;
  color: rgb(150, 150, 150);
  padding: 10px 5px 10px 0;
  resize: none;
}

.sv2-spdrn-ai-input-div::-webkit-scrollbar {
  width: 5px;
}

.sv2-spdrn-ai-input-div::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.sv2-spdrn-ai-input-div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  outline: none;
  border-radius: 5px;
}

.sv2-spdrn-ai-input-div::selection {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.sv2-spdrn-ai-input-div:focus {
  border: none;
}

.sv2-spdrn-ai-input-div::placeholder {
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1.5;
  border: none;
  height: 100%;
  color: rgb(100, 100, 100);
  // color: rgb(15, 114, 66);
}

.pro-form {
  color: rgb(220, 220, 220);
}

.pro-form::placeholder {
  color: rgb(150, 150, 150);
}

.sv2-spdrn-ai-input-submit {
  font-size: 16px;
  // height: 40px;
  width: 40px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgb(190, 190, 190);
}

.sv2-spdrn-ai-input-submit:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.125);
  // color: rgb(220, 220, 220);
  // background-color: rgb(26, 167, 99);
}

.sv2-spdrn-ai-input-pro-ctrlbox {
  display: flex;
  margin-bottom: 8px;
}

.sv2-spdrn-ai-input-pro-ctrlbox > * {
  margin-right: 8px;
}

.sv2-spdrn-ai-input-pro-btn {
  height: 32px;
  padding: 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  // margin-right: 8px;
  background-color: rgb(95, 96, 101);
  color: rgb(216, 217, 223);
  font-size: 12px;
  font-weight: 500;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
}

.pro-icon {
  margin-right: 8px;
}

@media only screen and (max-width: 400px) {
  .sv2-spdrn-ai-input-pro-btn {
    padding: 0px 2px;
    font-size: 10px;
  }
  .pro-icon {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .pro-icon {
    display: none !important;
  }

  .option-disabled {
    display: none !important;
  }
}

@media only screen and (max-width: 1070px) {
  .sv2-spdrn-ai-input-pro-btn {
    padding: 0px 15px;
  }
}

@media only screen and (max-width: 970px) {
  .sv2-spdrn-ai-input-pro-btn {
    padding: 0px 12px;
  }
}

@media only screen and (max-width: 500px) {
  .sv2-spdrn-ai-input-pro-btn {
    padding: 0px 7px;
  }
}

.pro-selected {
  color: rgb(255, 255, 255);
  background-color: rgba(26, 167, 99, 0.75);
}

.pro-disabled {
  color: rgb(216, 217, 223);
  cursor: not-allowed !important;
}

.pro-disabled:hover {
  cursor: not-allowed !important;
}

.sv2-spdrn-pro-option-base {
  padding: 2px 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.5px;
  line-height: 1.25;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.option-spacing {
  margin-right: 3px !important;
}

.option-btn {
  background-color: inherit;
  transition: color 0.2s ease-in;
}

.option-btn:hover {
  cursor: pointer;
  color: rgb(34, 224, 132);
}

.option-selected {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgb(34, 224, 132);
}

.option-disabled {
  cursor: not-allowed;
  filter: brightness(90%);
}

// .sv2-spdrn-ai-input-pro-btn:last-child {
//   margin-right: 0;
// }

.sv2-spdrn-ai-input-pro-btn:hover {
  cursor: pointer;
}

.sv2-spdrn-prompt-panel {
  width: 100%;
  max-height: calc(100% - 60px);
  padding: 10px;
  overflow: scroll;
}

.sv2-spdrn-prompt-panel:empty {
  padding: 8px 0 0 0;
}

.sv2-spdrn-prompt-panel > .sv2-spdrn-unit-prompt {
  margin: 5px 0;
}

.sv2-spdrn-unit-prompt:first-child {
  margin-top: 0;
}

.sv2-spdrn-unit-prompt:last-child {
  margin-bottom: 0;
}

.sv2-spdrn-unit-prompt {
  width: 100%;
  position: relative;
  background-color: var(--theme-main5);
  border-radius: 5px;
  padding: 10px;
  color: rgb(150, 150, 150);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  overflow-x: hidden;
}

.sv2-spdrn-what-script {
  width: 250px;
  padding: 5px;
  background-color: var(--theme-main2);
  color: var(--theme-color);
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
  // font-family: "Poppins", sans-serif;
  font-family: var(--global-font-family);
  line-height: 1.5;
  -webkit-animation: fadein 0.2s ease-out alternate;
  -moz-animation: fadein 0.2s ease-out alternate;
  animation: fadein 0.2s ease-out alternate;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

.sv2-spdrn-minioptions {
  width: 100%;
  padding: 8px;
  background-color: var(--theme-main);
  margin-bottom: 5px;
  border-radius: 4px;
}

.sv2-spdrn-minioptions:last-child {
  margin-bottom: 0px;
}

.sv2-spdrn-minioptions:hover {
  cursor: pointer;
  filter: brightness(115%);
}

.sv2-spdrn-logs-section {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
}

.sv2-spdrn-view-logs {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sv2-spdrn-logs-container {
  height: 300px;
  max-height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 58px;
  z-index: 3;
  padding: 10px;
}

.sv2-spdrn-logs {
  width: 100%;
  height: 100%;
  position: relative;
  color: var(--theme-color);
  background-color: var(--theme-main2);
  border-radius: 8px;
  white-space: pre-line;
  overflow: auto;
  line-height: 1.35;
  overflow: hidden;
}

.sv2-spdrn-log-header {
  width: 100%;
  height: 30px;
  background-color: var(--theme-main5);
  position:absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 8px 8px 0 0;
  z-index: 4;
}

.sv2-spdrn-log-content {
  width: 100%;
  height: 100%;
  position: relative;
  color: var(--theme-color);
  background-color: var(--theme-main2);
  padding: 40px 10px 10px 10px;
  white-space: pre-line;
  overflow: auto;
  line-height: 1.35;
  z-index: 3;
}

.sv2-spdrn-agent-tag {
  padding: 1px 5px;
  // border-radius: 3px;
  // color: rgb(29, 191, 113);
  font-size: 13px;
  text-transform: capitalize;
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
}

.sv2-agent-icons {
  margin-bottom: 10px;
  background-color: var(--theme-main3);
  padding: 3px 7px;
  width: max-content;
  border-radius: 4px;
  font-size: 12px;
}

.sv2-agent-general-title {
  font-family: 'Roboto Mono', 'Sans Mono', 'Consolas', 'Courier', monospace !important;
  font-weight: 700;
}

.sv2-simple-code-panel {
  position: absolute;
  z-index: 100;
  // background-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  top: 30px;
  right: 15px;
  overflow: hidden;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.sv2-authStorage-card {
  width: 100%;
  position: relative;
  padding: 7.5px 10px 7.5px 10px;
  border-radius: 7.5px;
  background-color: rgba(255, 255, 255, 0.1);
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: .75fr .5fr .5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 10px;

  .name {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    grid-area: 1 / 1 / 2 / 2;
  }
  .key {
    font-size: 11px;
    padding: 5px 0 12.5px 2.5px;
    grid-area: 2 / 1 / 3 / 2;
  }
  .description {
    // background-color: red;
    font-size: 11px;
    line-height: 1.5;
    grid-area: 3 / 1 / 4 / 2;
    opacity: 1;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.7;
  }
  .createdAt {
    font-size: 12px;
    font-style: italic;
    grid-area: 1 / 2 / 2 / 3;
    text-align: right;
  }
  .more {
    user-select: none;
    font-size: 11px;
    grid-area: 3 / 2 / 4 / 3;
    text-align: right;
  }
  .code-block {
    font-size: 14px;
    font-family: Roboto Mono;
    font-weight: 400;
    grid-area: 4 / 1 / 5 / 3;
  }
  .delete {
    border-radius: 3px;
    user-select: none;
    width: max-content;
    font-size: 12px;
    margin-top: 7.5px;
    // background-color: rgba(220, 20, 60, 0.1);
    padding: 2px 5px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    font-weight: 400;
    color: rgba(220, 20, 60, 1);
    // border: 1px solid rgba(220, 20, 60, 0.85);
  }
}

.sv2-authStorage-card:hover {
  .delete {
    opacity: 1;
  }
}

.sv2-authStorage-card > div {
  padding: 2.5px;
}